import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userIsRegistered } from "../../Redux/Slices/Login/Login";
import { Modal } from "react-bootstrap";

const initialState = {
    pendingModal: false,
    message: ''
}
const StepDetails = () => {
    const [iState, updateState] = useState(initialState)
    const { pendingModal, message } = iState
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleUserRegistration = () => {
        // Call the API to register the user and handle the response
        dispatch(userIsRegistered()).then((res) => {
            if (res?.payload?.status === 200) {
                console.log('res', res)
                if (res?.payload?.data?.isRegistered == true && res?.payload?.data?.astrologer?.astroStatus === 'approved') {
                    window.localStorage.setItem('astrologerToken', res?.payload?.data?.astrologer?.jwtToken)
                    navigate('/dashboard')
                } else if (res?.payload?.data?.isRegistered == false) {
                    navigate('/personal')
                } else {
                    //write msg to toastify or modal open 
                    handleOpenModal(res?.payload?.data?.astrologer?.astroStatus)
                }
            }
        })
    }
    const modalMessage = (message) => {

        //["new registration","interview","on-hold","rejected","approved"]

        if (message === 'new registration') {
            return 'Your account is still pending approval'
        }
        else if (message === 'on-hold') {
            return 'Your account verification/profile has been successfully verified, but it has been temporarily put on hold for further administrative review'
        }
        else if (message === 'interview') {
            return 'Your profile has been chosen for an interview'
        }
        else if (message === 'rejected') {
            return 'Your profile verification request has been rejected. We apologize for any inconvenience'
        }
    }
    const handleOpenModal = (data) => {
        updateState({ ...iState, pendingModal: true, message: modalMessage(data) })
    }
    const handleLogout = () => {
        console.log("handleLogout")
        window.localStorage.removeItem('astrologerToken');
        // navigate('/')

        navigate('/', { replace: true })
    }

    return (
        <>
            <div style={{ backgroundColor: "#f6f7ff" }}>
                <div className="Header Step">
                    <div className="HeaderLeft">
                        <div className="Logo">
                            <img src={require('../../Assets/images/Logo.png')} />
                        </div>
                    </div>
                    <div className="Navigation">
                        <div className="Avater">
                            <a >
                                <figure>
                                    <img src={require('../../Assets/images/profile.jpg')} />
                                </figure>
                                {/* Bob Hyden */}
                            </a>
                            <ul>
                                <li>
                                    <figure>
                                        <img src={require('../../Assets/images/profile.jpg')} />
                                    </figure>
                                    <h4>
                                        <span>Astrologer</span>
                                    </h4>
                                </li>
                                <li>
                                    <a
                                        data-toggle="modal"
                                        data-target="#LogOutModal"
                                        onClick={handleLogout}
                                    >
                                        <span >
                                            <i className="fa fa-sign-out" />
                                        </span>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="clear" />
                    </div>
                </div>
                <div
                    className="SmallWrapperBox"
                    style={{ width: "65%", margin: "100px auto 50px", }}
                >
                    <div className="ConsultantService CommonForm">
                        <form>
                            <div className="StepContentArea">
                                <figure>
                                    <img src={require("../../Assets/images/book-icon.png")} />
                                </figure>
                                <h2>
                                    Welcome to Astrotech! We're excited to have you join our community
                                    of talented astrologers. This guide will walk you through the
                                    process of creating your astrologer profile and becoming a valuable
                                    asset to our platform.
                                </h2>
                                <h6>

                                    <span>
                                        <img src={require('../../Assets/images/heading-icon.png')} />
                                    </span>
                                    After your first login, you'll be prompted to create your astrologer
                                    profile. Here's what you'll need to do:
                                </h6>
                                <ul>
                                    <li>
                                        <span>
                                            <img src={require('../../Assets/images/key-icon.png')} />
                                        </span>
                                        Step 1: Enter Personal Details.
                                    </li>
                                    <li>
                                        <span>
                                            <img src={require('../../Assets/images/key-icon.png')} />
                                        </span>
                                        Step 2: Skill or Experience Details
                                    </li>
                                    <li>
                                        <span>
                                            <img src={require('../../Assets/images/key-icon.png')} />
                                        </span>
                                        Step 3: Other Details
                                    </li>
                                    <li>
                                        <span>
                                            <img src={require('../../Assets/images/key-icon.png')} />
                                        </span>
                                        Step 4: Document Upload
                                    </li>
                                </ul>
                                <h6>

                                    <span>
                                        <img src={require('../../Assets/images/heading-icon.png')} />
                                    </span>
                                    After you have entered all the mandatory details and uploaded
                                    documents, you have to wait for the admin approval.
                                </h6>
                                <ul>
                                    <li>
                                        <span>
                                            <img src={require('../../Assets/images/key-icon.png')} />
                                        </span>
                                        Step 5: Admin Review
                                    </li>
                                    <li>
                                        <span>
                                            <img src={require('../../Assets/images/key-icon.png')} />
                                        </span>
                                        Step 6: Attend Interview
                                    </li>
                                    <li>
                                        <span>
                                            <img src={require('../../Assets/images/key-icon.png')} />
                                        </span>
                                        Step 7: Account Activation (after admin approval)
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-5 mx-auto">
                                <div className="Buttons">
                                    {/* <Link to="/personal" className="ContinueBtn">
                                    Proceed
                                </Link> */}

                                    <a onClick={handleUserRegistration} className="ContinueBtn">
                                        Proceed
                                    </a>

                                </div>
                            </div>
                        </form>
                    </div>
                    <Modal
                        show={pendingModal}
                        className="ModalBox">
                        <Modal.Body>
                            <div className="modal-body">
                                <a className="CloseModal" onClick={() => updateState({ ...iState, pendingModal: false })}>
                                    ×
                                </a>
                                <div className="LogOutModalArea">
                                    {/* <span>
                                    <img src={require('../../../Assets/images/logout-icon.png')} />
                                </span> */}
                                    <h5>Status</h5>
                                    <p>{message}</p>
                                    <div className="Buttons TwoButtons">
                                        {/* <button className="Button" onClick={() => updateState({ ...iState, showLogoutModal: false })}>
                                        No
                                    </button>
                                    <a
                                        type="button"
                                        onClick={handleLogout}
                                        className="Button Red"
                                    >
                                        Yes
                                    </a> */}
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                    </Modal>
                </div>
            </div >
        </>

    )
}

export default StepDetails;