import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const addSupportApiSlice = createAsyncThunk(
    "addSupportApi/postaddSupportApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `support/add-support-enquiry`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);


//              support listing                          

export const supportApiSlice = createAsyncThunk(
    "supportData/getsupportData",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `support/enquiry-list?search=${payload?.search}&startDate=${payload?.startDate}&endDate=${payload?.endDate}&timeFrame=${payload?.timeFrame}&page=${payload?.page}`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const supportResolutionApi = createAsyncThunk(
    "supportResolutionApi/getsupportResolutionApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `support/view-admin-notes?requestId=${payload?.id}`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

const initialState = {
    supportData: [],
    resolutionData: [],
    status: false
}
const supportDataSlice = createSlice({
    name: 'supportDataSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(supportApiSlice.pending, (state) => {
                state.status = true;
            })
            .addCase(supportApiSlice.fulfilled, (state, action) => {
                state.status = false;
                state.supportData = action.payload;
            })
            .addCase(supportApiSlice.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })
            .addCase(supportResolutionApi.pending, (state) => {
                state.status = true;
            })
            .addCase(supportResolutionApi.fulfilled, (state, action) => {
                state.status = false;
                state.resolutionData = action.payload;
            })
            .addCase(supportResolutionApi.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })
    }
})

export default supportDataSlice.reducer;