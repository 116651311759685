import React, { useEffect, useState } from 'react'
import Header from '../header/Header'
import SideNav from '../sidenav/Sidenav'
import { useDispatch } from 'react-redux'
import { addSupportApiSlice } from '../../../Redux/Slices/Support/Support'
import { uploadImage } from '../../../Utils/FileUpload'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getProfile } from '../../../Redux/Slices/Profile/profile'


const initialState = {
    firstName: "",
    email: "",
    reason: "",
    attachedFile: "",
    lastName: "",
    contact: "",
    message: "",
    errors: ""
}
const AddSupport = () => {
    const [iState, updateState] = useState(initialState)
    const { firstName, email, reason, attachedFile, lastName, contact, message, errors } = iState

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({ ...iState, [name]: value })
    }

    const handleAddSupport = (e) => {
        console.log('handle add support worked')
        e.preventDefault()
        const data = {
            firstName,
            email,
            contact: Number(contact),
            reason,
            message,
            fileAttach: attachedFile
        }
        if (handleValidation()) {
            dispatch(addSupportApiSlice(data)).then((res) => {
                console.log('add response', res)
                if (res?.payload?.status === 200) {
                    updateState(initialState)
                    toast.success('Support Added Successfully', {
                        position: 'top-right',
                        autoClose: 1000
                    })

                    navigate('/support')
                }
            })
        }
    }

    const handleAddFile = async (e) => {
        const { name } = e.target
        const file = e.target.files[0]
        const url = await uploadImage(file)
        updateState({ ...iState, [name]: url.location })

    }

    const handleValidation = () => {
        let error = {}
        let isValid = true
        if (!firstName) {
            error.firstName = "Full Name is required"
            isValid = false
        }
        if (!email) {
            error.email = "Email is required"
            isValid = false
        }
        // if (!lastName) {
        //     error.lastName = "Last Name is required"
        //     isValid = false
        // }
        if (!contact) {
            const numberPattern = /^[0-9]+$/
            if (!numberPattern.test(contact)) {
                error.contact = "Please enter a valid contact number"
                isValid = false
            }
        }
        if (!message) {
            error.message = "Message is required"
            isValid = false
        }
        if (!reason) {
            error.reason = "Reason is required"
            isValid = false
        }
        if (!attachedFile) {
            error.attachedFile = "Please select a file to attach"
            isValid = false
        }
        updateState({
            ...iState,
            errors: error
        })
        return isValid
    }

    useEffect(() => {
        dispatch(getProfile()).then((res) => {
            if (res?.payload?.status === 200) {
                console.log('res', res)
                updateState({
                    ...iState,
                    firstName: res?.payload?.data?.fullName,
                    email: res?.payload?.data?.email,
                    contact: res?.payload?.data?.contact
                })
            }
        })
    }, [])

    return (
        <div>
            <Header />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title">Support</h4>
                        <div className="BackArrow m-0">
                            <a onClick={() => navigate(-1)}>
                                <i className="fa fa-long-arrow-left" /> Back
                            </a>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="NewHeading">
                            <h3>Submit your issue to our Support Team</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam a
                                volutpat lacus. Duis est arcu, tincidunt vel tristique id, dignissim
                                ac metus. Pellent esque semper ligula massa, ut fringilla ipsum
                                ullamcorper et. Nunc eleme ntum consequat leo. Suspendisse egestas
                                felis nibh, id mattis urna sempe r ornare. Sed hendrerit nulla nulla
                            </p>
                        </div>
                        <div className="CommonForm">
                            <form>
                                <div className="UserPlanBox">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Full Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter First Name"
                                                    name='firstName'
                                                    value={firstName}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                                <span className='text-danger'>{errors.firstName}</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Email ID</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Email ID"
                                                    name='email'
                                                    value={email}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                                <span className='text-danger'>{errors.email}</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Reason for Enquiry</label>
                                                <select className="form-control" name='reason' value={reason} onChange={handleChange}>
                                                    <option value="">--select reason--</option>
                                                    <option value="1">Reason 1</option>
                                                    <option value="2">Reason 2</option>
                                                    <option value="3">Reason 3</option>
                                                </select>
                                                <span className='text-danger'>{errors.reason}</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Attach *</label>
                                                <input type="file" className="form-control" name='attachedFile' onChange={handleAddFile} />
                                                <span className='text-danger'>{errors.attachedFile}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            {/* <div className="form-group">
                                                <label>Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Last Name"
                                                    name='lastName'
                                                    value={lastName}
                                                    onChange={handleChange}
                                                />
                                                <span className='text-danger'>{errors.lastName}</span>
                                            </div> */}
                                            <div className="form-group">
                                                <label>Contact Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Contact Number"
                                                    name='contact'
                                                    value={contact}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                                <span className='text-danger'>{errors.contact}</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Message</label>
                                                <textarea
                                                    className="form-control"
                                                    rows={6}
                                                    placeholder="Write here.."
                                                    name='message'
                                                    value={message}
                                                    onChange={handleChange}
                                                />
                                                <span className='text-danger'>{errors.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="Button" onClick={handleAddSupport}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddSupport