import React, { useEffect, useState } from "react";
import StepBox from "./StepBox";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ProfileUpdate } from "../../Redux/Slices/ProfileUpdate/ProfileUpdate";
import { userIsRegistered } from "../../Redux/Slices/Login/Login";
import { uploadImage } from "../../Utils/FileUpload";


const initialState = {
    fullName: '',
    phoneNumber: '',
    email: '',
    profileImg: "",
    rememberMe: false,
    pageStatus: "",
    errors: {}
}

const Personal = () => {

    const [cState, updateCState] = useState(initialState);

    const {
        fullName,
        phoneNumber,
        email,
        rememberMe,
        profileImg,
        pageStatus,
        errors
    } = cState

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target
        if (name === 'rememberMe') {
            updateCState({
                ...cState, [name]: checked
            })
        } else {
            updateCState({
                ...cState, [name]: value
            })
        }
    }

    console.log("cState", cState);

    const handleValidation = () => {
        let error = {}
        let isValid = true
        if (!profileImg) {
            error.profileImg = 'Please provide Profile Image'
            isValid = false
        }

        updateCState({
            ...cState,
            errors: error
        })

        return isValid
    }

    const handleFileUpload = async (e) => {
        const { name } = e.target
        const file = e.target.files[0];
        const url = await uploadImage(file);
        updateCState({ ...cState, "profileImg": url.location });
    }

    const handleLogin = (e) => {
        e.preventDefault()
        const data = {
            fullName: fullName,
            email,
            contact: Number(phoneNumber),
            rememberMe: rememberMe,
            image: profileImg,
            pageStatus: '1'
        }
        console.log('error', handleValidation(), errors)
        if (handleValidation()) {
            dispatch(ProfileUpdate(data)).then((res) => {
                console.log('before', res);
                if (res?.payload?.status === 200) {
                    navigate('/skills')
                }
            })
        }

    }

    const handleLogout = () => {
        console.log("handleLogout")
        window.localStorage.removeItem('astrologerToken');
        navigate('/')
    }

    useEffect(() => {
        dispatch(userIsRegistered()).then((res) => {
            if (res?.payload?.status === 200) {
                updateCState({
                    ...cState,
                    fullName: res?.payload?.data?.astrologer?.fullName,
                    phoneNumber: res?.payload?.data?.astrologer?.contact,
                    email: res?.payload?.data?.astrologer?.email,
                    profileImg: res?.payload?.data?.astrologer?.image,
                    pageStatus: res?.payload?.data?.astrologer?.pageStatus,
                })
            }
        })
    }, [])
    return (
        <>
            <div style={{ backgroundColor: "#f6f7ff" }}>
                <div className="Header Step">
                    <div className="HeaderLeft">
                        <div className="Logo">
                            <img src={require('../../Assets/images/Logo.png')} />
                        </div>
                    </div>
                    <div className="Navigation">
                        <div className="Avater">
                            <a >
                                <figure>
                                    <img src={require('../../Assets/images/profile.jpg')} />
                                </figure>
                                {/* Bob Hyden */}
                            </a>
                            <ul>
                                <li>
                                    <figure>
                                        <img src={require('../../Assets/images/profile.jpg')} />
                                    </figure>
                                    <h4>
                                        <span>Astrologer</span>
                                    </h4>
                                </li>
                                <li>
                                    <a
                                        data-toggle="modal"
                                        data-target="#LogOutModal"
                                        onClick={handleLogout}
                                    >
                                        <span >
                                            <i className="fa fa-sign-out" />
                                        </span>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="clear" />
                    </div>
                </div>
                <StepBox page={pageStatus} />
                <div className="SmallWrapperBox" style={{ width: "40%" }}>
                    <div className="ConsultantService CommonForm">
                        <form onSubmit={handleLogin}>
                            <div className="UserBoxNew">
                                <figure>
                                    <span>
                                        <img src={profileImg} alt="upload file" />

                                    </span>
                                    <div className="Overlay">
                                        <i className="fa-regular fa-pen-to-square" />
                                        <input type="file" name="profileImg" onChange={handleFileUpload} />

                                    </div>

                                </figure>

                            </div>
                            <span style={{ color: 'red' }}>{errors.profileImg}</span>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Full Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Full Name"
                                            name="fullName"
                                            value={fullName}
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Mobile No.</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Mobile No."
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Email ID</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Email ID"
                                            name="email"
                                            value={email}
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="Buttons TwoColorButtons">
                                {/* <Link to='/step-details' class="ContinueBtn Red">Back</Link> */}
                                <button type="submit"
                                    //href="skill-experience-details.html" 
                                    className="ContinueBtn ml-0">
                                    Save &amp; Continue
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Personal;