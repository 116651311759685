
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";




export const faqApi = createAsyncThunk(
    "faqApi/getfaqApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `faq/faq-list`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

const initialState = {
    faqData: [],
    status: false
}
const faqDataSlice = createSlice({
    name: 'faqSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(faqApi.pending, (state) => {
                state.status = true;
            })
            .addCase(faqApi.fulfilled, (state, action) => {
                state.status = false;
                state.faqData = action.payload;
            })
            .addCase(faqApi.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })
    }
})

export default faqDataSlice.reducer;