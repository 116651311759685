import React, { useEffect } from "react";
import StepBox from "./StepBox";
import { userIsRegistered } from "../../Redux/Slices/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { cityListApi, countryListApi, stateListApi } from "../../Redux/Slices/Location/location";
import { Link, useNavigate } from "react-router-dom";
import { ProfileUpdate } from "../../Redux/Slices/ProfileUpdate/ProfileUpdate";

const initialState = {
    displayName: "",
    gender: "",
    dob: "",
    addr1: "",
    addr2: "",
    country: "",
    state: "",
    city: "",
    pin: "",
    maritalStatus: "",
    languages: [],
    yoe: "",
    skills: [],
    dailyHours: "",
    about: "",
    errors: {}
}
const Skills = () => {
    const [iState, updateState] = React.useState(initialState);
    const { displayName, gender, dob, addr1, addr2, country, state, city, pin, maritalStatus, languages, yoe, skills, dailyHours, about, errors } = iState
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const countryList = useSelector((state) => state?.locationData?.countryList)
    const stateList = useSelector((state) => state?.locationData?.stateList)
    const cityList = useSelector((state) => state?.locationData?.cityList)
    console.log('city list', cityList)
    console.log('state list', stateList)
    console.log('country list', countryList)


    const languageOptions = [
        { name: 'English', id: 1 },
        { name: 'Hindi', id: 2 },
        { name: 'Bengali', id: 3 },
        { name: 'Tamil', id: 4 },
        // Add more languages here
    ];

    const skillOptions = [
        { name: 'Vedic Astrology', id: 1 },
        { name: 'Tarot Reading', id: 2 },
        { name: 'Numerology', id: 3 },
        { name: 'Palmistry', id: 4 },
        // Add more skills here
    ];

    const handleSkillSelect = (selectedList) => {
        updateState({ ...iState, skills: selectedList });
    };


    const currentDate = new Date();
    const year18YearsAgo = currentDate.getFullYear() - 18;
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(currentDate.getDate()).padStart(2, "0");

    const maxDate = `${year18YearsAgo}-${month}-${day}`;

    const handleChange = (e) => {
        const { name, value } = e.target;
        const valueString = String(value);

        // Limit the input to 6 digits
        if (valueString.length <= 6 && name === 'pin') {
            updateState({ ...iState, [name]: valueString });
        } else {
            updateState({ ...iState, [name]: value });
        }

    }

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     const valueString = String(value);

    //     if (name === 'pin') {
    //         // Always limit 'pin' to 6 digits
    //         if (valueString.length <= 6) {
    //             updateState({ ...iState, [name]: valueString });
    //         }
    //     } else {
    //         // Handle other input fields as usual
    //         updateState({ ...iState, [name]: value });
    //     }
    // };


    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     // ... other input handling

    //     if (name === "dob") {
    //         const selectedDate = new Date(value);
    //         const currentDate = new Date();
    //         const minDate = new Date();
    //         minDate.setFullYear(currentDate.getFullYear() - 18); // Set minimum date to 18 years ago

    //         if (selectedDate > minDate) {
    //             // Show error message or clear the input field
    //             updateState({ ...iState, errors: { ...iState.errors, dob: "You must be at least 18 years old." } });
    //         } else {
    //             // Clear error if it was set before
    //             updateState({ ...iState, dob: value, errors: { ...iState.errors, dob: "" } });
    //         }
    //     }
    // };


    const handleSelect = (selectedList) => {
        updateState({ ...iState, languages: selectedList });
        // setShow({ ...show, eventLanguages: selectedList });
    };


    const handleGenderChange = (e) => {
        updateState({ ...iState, gender: e.target.value });
    };

    const handleValidation = () => {
        let error = {};
        let isValid = true;

        if (!displayName) {
            error.displayName = "Name is required";
            isValid = false;
        }

        if (!gender) {
            error.gender = "Gender is required";
            isValid = false;
        }

        if (!dob) {
            error.dob = "Date of Birth is required";
            isValid = false;
        }

        if (!addr1) {
            error.addr1 = "Address Line 1 is required";
            isValid = false;
        }

        if (!country) {
            error.country = "Country is required";
            isValid = false;
        }

        if (!state) {
            error.state = "State is required";
            isValid = false;
        }

        if (!city) {
            error.city = "City is required";
            isValid = false;
        }

        if (!pin) {
            error.pin = "Pin Code is required";
            isValid = false;
        } else if (!/^\d{6}$/.test(pin)) {
            error.pin = "Pin Code should be 6 digits";
            isValid = false;
        }

        if (!maritalStatus) {
            error.maritalStatus = "Marital Status is required";
            isValid = false;
        }

        if (languages.length === 0) {
            error.languages = "Please select at least one language";
            isValid = false;
        }

        if (!yoe) {
            error.yoe = "Experience is required";
            isValid = false;
        } else if (!/^\d+$/.test(yoe)) {
            error.yoe = "Experience should be a number";
            isValid = false;
        }

        if (skills.length === 0) {
            error.skills = "Please select at least one skill";
            isValid = false;
        }

        if (!dailyHours) {
            error.dailyHours = "Daily Hours is required";
            isValid = false;
        } else if (!/^\d+$/.test(dailyHours)) {
            error.dailyHours = "Daily Hours should be a number";
            isValid = false;
        }

        if (!about) {
            error.about = "About is required";
            isValid = false;
        }


        updateState({
            ...iState,
            errors: error,
        });

        return isValid;
    };


    const handleSubmitSkill = () => {
        const data = {
            fullName: displayName,
            gender,
            dob,
            address1: addr1,
            address2: addr2,
            country,
            state,
            city,
            pincode: Number(pin),
            maritalStatus: maritalStatus,
            language: languages,
            experience: yoe,
            skills,
            hoursContribute: dailyHours,
            aboutYou: about
        }
        console.log('data', data, 'isValid', handleValidation())
        // call api here for submiting form
        if (handleValidation()) {
            dispatch(ProfileUpdate(data)).then((res) => {
                console.log('before', res);
                if (res?.payload?.status === 200) {
                    navigate('/fill-others')
                }
            })
        }

    }

    console.log('iState', iState)

    useEffect(() => {
        dispatch(userIsRegistered()).then((res) => {
            if (res?.payload?.status === 200) {
                console.log('res', res?.payload?.data?.astrologer)
                updateState({
                    ...iState,
                    displayName: res?.payload?.data?.astrologer?.fullName ?? "",
                    gender: res?.payload?.data?.astrologer?.gender ?? "",
                    dob: res?.payload?.data?.astrologer?.dob ?? "",
                    addr1: res?.payload?.data?.astrologer?.address1 ?? "",
                    addr2: res?.payload?.data?.astrologer?.address2 ?? "",
                    country: res?.payload?.data?.astrologer?.country ?? "",
                    state: res?.payload?.data?.astrologer?.state ?? "",
                    city: res?.payload?.data?.astrologer?.city ?? "",
                    pin: Number(res?.payload?.data?.astrologer?.pincode) ?? Number(""),
                    maritalStatus: res?.payload?.data?.astrologer?.maritalStatus ?? "",
                    languages: res?.payload?.data?.astrologer?.language ?? [],
                    yoe: res?.payload?.data?.astrologer?.experience ?? "",
                    skills: res?.payload?.data?.astrologer?.skills ?? [],
                    dailyHours: res?.payload?.data?.astrologer?.hoursContribute ?? "",
                    about: res?.payload?.data?.astrologer?.aboutYou ?? ""
                })
            }
        })
        dispatch(countryListApi())
    }, [])

    useEffect(() => {
        if (country) {
            dispatch(stateListApi(country))
        }
    }, [country])

    useEffect(() => {
        if (state && country) {
            const data = {
                country,
                state
            }
            dispatch(cityListApi(data))
        }
    }, [state])


    const handleLogout = () => {
        console.log("handleLogout")
        window.localStorage.removeItem('astrologerToken');
        navigate('/')
    }

    return (
        <>
            <div style={{ backgroundColor: "#f6f7ff" }}>
                <div className="Header Step">
                    <div className="HeaderLeft">
                        <div className="Logo">
                            <img src={require('../../Assets/images/Logo.png')} />
                        </div>
                    </div>
                    <div className="Navigation">
                        <div className="Avater">
                            <a >
                                <figure>
                                    <img src={require('../../Assets/images/profile.jpg')} />
                                </figure>
                                {/* Bob Hyden */}
                            </a>
                            <ul>
                                <li>
                                    <figure>
                                        <img src={require('../../Assets/images/profile.jpg')} />
                                    </figure>
                                    <h4>
                                        <span>Astrologer</span>
                                    </h4>
                                </li>
                                <li>
                                    <a
                                        data-toggle="modal"
                                        data-target="#LogOutModal"
                                        onClick={handleLogout}
                                    >
                                        <span >
                                            <i className="fa fa-sign-out" />
                                        </span>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="clear" />
                    </div>
                </div>
                <div>
                    <StepBox />
                    <div className="SmallWrapperBox" style={{ width: "40%" }}>
                        <div className="ConsultantService CommonForm">
                            <form>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Display Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Display Name"
                                                name="displayName"
                                                value={displayName}
                                                onChange={handleChange}
                                            />
                                            {errors.displayName && <span className="text-danger">{errors.displayName}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Gender</label>
                                            <ul className="GenderUl">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Male
                                                        <input type="radio" name="gender" checked={gender === 'male' ? true : false} value='male' onChange={handleGenderChange} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Female
                                                        <input type="radio" name="gender" checked={gender === 'female' ? true : false} value='female' onChange={handleGenderChange} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Others
                                                        <input type="radio" name="gender" checked={gender === 'others' ? true : false} value='others' onChange={handleGenderChange} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                            {errors.gender && <span className="text-danger">{errors.gender}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Date of Birth</label>
                                            {/* <input type="date" className="form-control" name="dob" value={dob} onChange={handleChange} /> */}
                                            <input type="date" className="form-control" name="dob" value={dob} onChange={handleChange}
                                                max={maxDate}
                                            />
                                            {errors.dob && <span className="text-danger">{errors.dob}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Address Line 1</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Address Line 1"
                                                name="addr1"
                                                value={addr1}
                                                onChange={handleChange}
                                            />
                                            {errors.addr1 && <span className="text-danger">{errors.addr1}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Address Line 2</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Address Line 2"
                                                name="addr2"
                                                value={addr2}
                                                onChange={handleChange}
                                            />
                                            {/* {errors.addr2 && <span className="text-danger">{errors.addr2}</span>} */} {/* Address Line 2 is optional */}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Country</label>
                                                    <select className="form-control" name="country" value={country} onChange={handleChange}>
                                                        <option value="">--select country</option>
                                                        {

                                                            countryList?.data?.map((item) => {
                                                                return <option value={item.isoCode}>{item.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {errors.country && <span className="text-danger">{errors.country}</span>}
                                                </div>
                                                <div className="form-group">
                                                    <label>State</label>
                                                    <select className="form-control" name="state" value={state} onChange={handleChange}>
                                                        <option>--select state</option>
                                                        {
                                                            stateList?.data?.map((item) => {
                                                                return <option value={item.isoCode}>{item.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {errors.state && <span className="text-danger">{errors.state}</span>}
                                                </div>
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <select

                                                        className="form-control"
                                                        placeholder="Enter City"
                                                        name="city"
                                                        value={city}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">--Select City</option>
                                                        {

                                                            cityList?.data?.map((item) => {
                                                                return <option value={item.name}>{item.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {errors.city && <span className="text-danger">{errors.city}</span>}
                                                </div>
                                                <div className="form-group">
                                                    <label>Pin Code</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter Pincode"
                                                        name="pin"
                                                        value={pin}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.pin && <span className="text-danger">{errors.pin}</span>}
                                                </div>

                                            </div>

                                        </div>
                                        <div className="form-group">
                                            <label>Marital Status</label>
                                            <select className="form-control" name="maritalStatus" value={maritalStatus} onChange={handleChange}>
                                                <option>--select--</option>
                                                <option value='married'>Married</option>
                                                <option value='unmarried'>Unmarried</option>
                                            </select>
                                            {errors.maritalStatus && <span className="text-danger">{errors.maritalStatus}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Language</label>
                                            <div className="SelectLanBox">
                                                <Multiselect
                                                    options={languageOptions}
                                                    selectedValues={languages}
                                                    onSelect={handleSelect}
                                                    onRemove={handleSelect}
                                                    displayValue="name"
                                                    placeholder="--Select--"
                                                />
                                            </div>
                                            {errors.languages && <span className="text-danger">{errors.languages}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Experience (in years)</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Experience (in years)"
                                                name="yoe"
                                                value={yoe}
                                                onChange={handleChange}
                                            />
                                            {errors.yoe && <span className="text-danger">{errors.yoe}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Skills</label>
                                            <div className="SelectLanBox">
                                                <Multiselect
                                                    options={skillOptions}
                                                    selectedValues={skills}
                                                    onSelect={handleSkillSelect}
                                                    onRemove={handleSkillSelect}
                                                    displayValue="name"
                                                    placeholder="--Select Skills--"
                                                />
                                            </div>
                                            {errors.skills && <span className="text-danger">{errors.skills}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>How many hours you can contribute daily?</label>
                                            <input type="text" className="form-control" name="dailyHours" value={dailyHours} onChange={handleChange} />
                                            {errors.dailyHours && <span className="text-danger">{errors.dailyHours}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>About Yourself</label>
                                            <textarea
                                                rows={4}
                                                className="form-control"
                                                placeholder="Write here.."
                                                name="about"
                                                value={about}
                                                onChange={handleChange}
                                            />
                                            {errors.about && <span className="text-danger">{errors.about}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="Buttons TwoColorButtons">
                                    <Link className="ContinueBtn Red" to='/personal'>
                                        Back
                                    </Link>
                                    <a className="ContinueBtn" onClick={handleSubmitSkill}>
                                        Save &amp; Continue
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Skills;