import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const getProfile = createAsyncThunk(
    "getProfile/getProfile",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `my-profile`,
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);