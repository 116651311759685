import React from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";

const Wallet = () => {
    return (
        <>
            <SideNav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title">Wallet</h4>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="WithdrawBox">
                                <aside>
                                    <span className="Icon">
                                        <img src={require('../../../Assets/images/CreditCard.png')} />
                                    </span>
                                    <p>Total Earning (in Rs.)</p>
                                    <h2>$ 149.00</h2>
                                </aside>
                                <a
                                    href="javscript:void(0);"
                                    className="WithdarwBtn"
                                    data-toggle="modal"
                                    data-target="#AddbankAccount"
                                >
                                    Withdraw
                                </a>
                                <span>
                                    (Withdrawal Policy: Lorem Ipsum jhuhfde loewnk lkipz nagrui)
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            <img src={require('../../../Assets/images/appointment-icon-2.png')} />
                                        </span>
                                        <h2>Total Completed Bookings</h2>
                                        <article>
                                            <h3>1251</h3>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            <img src={require('../../../Assets/images/total-amount.png')} />
                                        </span>
                                        <h2>Total Amount (in Rs.)</h2>
                                        <article>
                                            <h3>25</h3>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            <img src={require('../../../Assets/images/total-deductions.png')} />
                                        </span>
                                        <h2>Total Commission Deductions (in Rs.)</h2>
                                        <article>
                                            <h3>25</h3>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="DashboardOverview">
                                        <span>
                                            <img src={require('../../../Assets/images/total-withdrawn.png')} />
                                        </span>
                                        <h2>
                                            Total Amount Withdrawn <br /> (in Rs.)
                                        </h2>
                                        <article>
                                            <h3>25</h3>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row"></div>
                    <div className="TitleBox">
                        <h4 className="Title">Transaction Details</h4>
                    </div>
                    <div className="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Transaction ID</th>
                                    <th>Transaction Date &amp; Time</th>
                                    <th>Status</th>
                                    <th>Amount (in Rs.)</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01</td>
                                    <td>T-234</td>
                                    <td>22-02-23, 09:00 AM</td>
                                    <td>
                                        <span className="Green">Successful</span>
                                    </td>
                                    <td>5000</td>
                                    <td>
                                        <span className="Orange">Download Payment Invoice</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>T-234</td>
                                    <td>22-02-23, 09:00 AM</td>
                                    <td>
                                        <span className="Green">Successful</span>
                                    </td>
                                    <td>5000</td>
                                    <td>
                                        <span className="Orange">Download Payment Invoice</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>T-234</td>
                                    <td>22-02-23, 09:00 AM</td>
                                    <td>
                                        <span className="Green">Successful</span>
                                    </td>
                                    <td>5000</td>
                                    <td>
                                        <span className="Orange">Download Payment Invoice</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>T-234</td>
                                    <td>22-02-23, 09:00 AM</td>
                                    <td>
                                        <span className="Green">Successful</span>
                                    </td>
                                    <td>5000</td>
                                    <td>
                                        <span className="Orange">Download Payment Invoice</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>T-234</td>
                                    <td>22-02-23, 09:00 AM</td>
                                    <td>
                                        <span className="Green">Successful</span>
                                    </td>
                                    <td>5000</td>
                                    <td>
                                        <span className="Orange">Download Payment Invoice</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Wallet;