import React from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";

const BankDetails = () => {
    return (
        <>
            <SideNav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title">Bank Details</h4>
                        <a
                            className="TitleLink"
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#AddBankAccount"
                        >
                            Add Bank Account{" "}
                        </a>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>Search</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here.."
                                    />
                                </div>
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button">Apply</button>
                                    <button className="Button Cancel">
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select className="form-control">
                                        <option>Select </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Bank Name</th>
                                    <th>Branch Name</th>
                                    <th>Accound ID</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>001</td>
                                    <td>State Bank of India</td>
                                    <td>Krishna Nagar</td>
                                    <td>XXXXXXXXX3456</td>
                                    <td>
                                        <div className="Actions">
                                            <a
                                                className="Blue"
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#EdiBankAccount"
                                            >
                                                <i className="fa fa-pencil" />
                                            </a>
                                            <a
                                                className="Red"
                                                data-toggle="modal"
                                                data-target="#AccountDelete"
                                            >
                                                <i className="fa fa-trash" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="Pagination">
                        <ul>
                            <li>
                                <a href="javascript:void(0);">
                                    <i className="fa fa-angle-double-left" />
                                </a>
                            </li>
                            <li className="active">
                                <a href="javascript:void(0);">1</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">2</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">3</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <i className="fa fa-angle-double-right" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>

    )
}

export default BankDetails;