import React, { useEffect, useState } from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { supportApiSlice } from "../../../Redux/Slices/Support/Support";
import moment from "moment";
import Pagination from "react-js-pagination";


const initialState = {
    search: "",
    startDate: "",
    endDate: "",
    timeFrame: "",
}
const Support = () => {
    const [iState, updateState] = useState(initialState)
    const { search, startDate, endDate, timeFrame } = iState
    const dispatch = useDispatch()
    const supportList = useSelector((state) => state?.support?.supportData)
    const [ActivePage, updateActivePage] = useState(1)
    const [serialNo, updateSerialNo] = useState(10);
    console.log('support list', supportList)

    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState,
            [name]: value
        })
    }

    const handleFilter = () => {
        const data = {
            search: search,
            startDate: startDate,
            endDate: endDate,
            timeFrame: timeFrame
        }
        dispatch(supportApiSlice(data))
    }


    const handleRefresh = () => {
        updateState(initialState)
        const data = {
            search: "",
            startDate: "",
            endDate: "",
            timeFrame: ""
        }
        dispatch(supportApiSlice(data))
    }

    const handlePageChange = (pageNumber) => {
        let ser = pageNumber * 10;
        updateSerialNo(ser);
        updateActivePage(pageNumber);
        let data = {
            search: "",
            startDate: "",
            endDate: "",
            timeFrame: "",
            page: pageNumber,
        };
        dispatch(supportApiSlice(data))
    };

    useEffect(() => {
        const data = {
            search: search,
            startDate: startDate,
            endDate: endDate,
            timeFrame: timeFrame,
            page: '1'
        }
        dispatch(supportApiSlice(data)).then((res) => {
            console.log(res)
        })
    }, [])
    return (
        <>
            <SideNav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title">Support</h4>
                        <Link to='/addSupport' className="TitleLink">
                            New Support Enquiry
                        </Link>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>Search</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here.."
                                        name="search"
                                        value={search}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="startDate"
                                        max={new Date().toISOString().split("T")[0]} // Convert to 'YYYY-MM-DD'
                                        value={startDate}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="endDate"
                                        min={startDate} // Ensures 'To Date' cannot be earlier than 'From Date'
                                        max={new Date().toISOString().split("T")[0]} // Convert to 'YYYY-MM-DD'
                                        value={endDate}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button" onClick={handleFilter}>Apply</button>
                                    <button className="Button Cancel" onClick={handleRefresh} style={{ marginLeft: "5px" }}>
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select className="form-control" name="timeFrame" value={timeFrame} onChange={handleChange}>
                                        <option value="">Select </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Support Ticket ID</th>
                                    <th>Date of Enquiry</th>
                                    <th>Reason of Enquiry</th>
                                    <th>Enquiry Status</th>
                                    <th>View Enquiry Details</th>
                                    <th>View Admin Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    supportList?.data?.result?.length > 0 ? supportList?.data?.result?.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{item?.supportNo}</td>
                                                <td>{moment(item?.createdAt).format('DD/MM/YYYY')}</td>
                                                <td>{item?.reason}</td>
                                                <td style={{ textTransform: "none" }}>{item?.enquiryStatus}</td>
                                                <td>
                                                    <div className="Actions justify-content-start">
                                                        <Link to='/supportDetail' className="Blue ml-0" state={item}>
                                                            <i className="fa fa-eye" />
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="Actions justify-content-center">
                                                        <Link to='/supportNotes' className="Blue ml-0" state={item?._id}>
                                                            <i className="fa fa-eye" />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }) : "No Data found"
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="pagination">
                        <ul>
                            {supportList?.data?.result?.length > 0 && (
                                <Pagination
                                    activePage={ActivePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={supportList?.data?.totalDocuments}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            )}
                        </ul>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Support;