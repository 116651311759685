import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { userRegistration } from '../../Redux/Slices/Login/Login'


const initialState = {
    name: '',
    email: '',
    errors: ''

}
const CreateAccount = () => {
    const [iState, updateState] = useState(initialState)
    const { state } = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    console.log('state', state)
    const { name, email, errors } = iState

    const handleValidation = () => {
        let isValid = true;
        let error = {};

        // Name validation:
        if (!name) {
            error.name = 'Name is required';
            isValid = false;
        } else if (!/^[A-Za-z\s]+$/.test(name)) { // Check if name contains only letters and spaces
            error.name = 'Name should contain only alphabets';
            isValid = false;
        }

        // Email validation:
        if (!email) {
            error.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            error.email = 'Invalid email format';
            isValid = false;
        }

        updateState({
            ...iState,
            errors: error
        });

        return isValid;
    };




    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({ ...iState, [name]: value })
    }

    const handleCreaeteUser = () => {
        if (handleValidation()) {
            //call your api here
            const data = {
                fullName: name,
                email: email,
                contact: Number(state?.contact)
            }
            dispatch(userRegistration(data)).then((res) => {
                console.log('res', res)
                if (res?.payload?.status === 200) {
                    window.localStorage.setItem('astrologerToken', res?.payload?.data?.jwtToken)
                    navigate('/step-details')
                }
            })
        }
    }
    return (
        <div>
            <div className="LoginArea">
                <div className="LoginBox">
                    <figure>
                        <img src={require('../../Assets/images/Logo.png')} />
                    </figure>
                    <form>
                        <h3>Create Account</h3>
                        <p>Enter your full name and email Id to access astrologer panel.</p>
                        <div className="form-group">
                            <label>Enter Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Name"
                                name='name'
                                value={name}
                                onChange={handleChange}
                            />
                            <span className='text-danger'>{errors.name}</span>
                        </div>
                        <div className="form-group">
                            <label>Enter Email</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Email"
                                name='email'
                                value={email}
                                onChange={handleChange}
                            />
                            <span className='text-danger'>{errors.email}</span>
                        </div>
                        <a className="Button" onClick={handleCreaeteUser}>
                            Proceed
                        </a>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default CreateAccount