import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const ProfileUpdate = createAsyncThunk(
    "personalApi/postpersonalApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.put(
                `update-profile`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);