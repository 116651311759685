import React from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";

const Appointment = () => {
    return (
        <>
        <SideNav />
        <Header />
        <div className="WrapperArea">
            <div className="WrapperBox">
                <div className="CommonTabs">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#Upcoming">
                                Upcoming
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#Completed">
                                Completed
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#Cancelled">
                                Cancelled
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#NoShow">
                                No Show
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="Upcoming">
                        <div className="TitleBox justify-content-end m-0">
                            <a
                                href="javascript:void(0);"
                                className="TitleLink"
                                style={{ marginTop: "-85px" }}
                            >
                                Download CSV
                            </a>
                        </div>
                        <div className="Small-Wrapper">
                            <div className="FilterArea">
                                <div className="FilterLeft">
                                    <div className="form-group">
                                        <label>Search</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here.."
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <button className="Button">Apply</button>
                                        <button className="Button Cancel">
                                            <i className="fa fa-refresh" />
                                        </button>
                                    </div>
                                </div>
                                <div className="FilterRight">
                                    <div className="form-group">
                                        <label>Timeframe</label>
                                        <select className="form-control">
                                            <option>Select </option>
                                            <option value="Today">Today</option>
                                            <option value="Week">This Week</option>
                                            <option value="Month">This Month</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="TableList">
                            <table style={{ width: "120%" }}>
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Appointment ID</th>
                                        <th>Service Name</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Call Type &amp; Duration</th>
                                        <th>View User Details</th>
                                        <th>
                                            View Previous <br /> Consultation Details
                                        </th>
                                        <th>Reschedule/Cancel</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>01</td>
                                        <td>A-141</td>
                                        <td>Marriage Counselling</td>
                                        <td>22-02-23</td>
                                        <td>09:00 AM</td>
                                        <td>Online (Video Call 30 min)</td>
                                        <td>
                                            <div className="Actions justify-content-start">
                                                <a href="upcoming-details.html" className="Blue ml-0">
                                                    <i className="fa fa-eye" />
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Actions justify-content-start">
                                                <a
                                                    href="previous-consultant-details.html"
                                                    className="Blue ml-0"
                                                >
                                                    <i className="fa fa-eye" />
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="NewActions justify-content-center">
                                                <a href="support-enquiry.html" className="Red">
                                                    Reschedule
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="NewActions justify-content-center">
                                                <a href="javascript:void(0);" className="Green">
                                                    Call
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="Completed">
                        <div className="TitleBox justify-content-end m-0">
                            <a
                                href="javascript:void(0);"
                                className="TitleLink"
                                style={{ marginTop: "-85px" }}
                            >
                                <i className="fa fa-download" /> Download CSV
                            </a>
                        </div>
                        <div className="Small-Wrapper">
                            <div className="FilterArea">
                                <div className="FilterLeft">
                                    <div className="form-group">
                                        <label>Search</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here.."
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <button className="Button">Apply</button>
                                        <button className="Button Cancel">
                                            <i className="fa fa-refresh" />
                                        </button>
                                    </div>
                                </div>
                                <div className="FilterRight">
                                    <div className="form-group">
                                        <label>Timeframe</label>
                                        <select className="form-control">
                                            <option>Select </option>
                                            <option value="Today">Today</option>
                                            <option value="Week">This Week</option>
                                            <option value="Month">This Month</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="TableList">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Appointment ID</th>
                                        <th>Service Name</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Call Type &amp; Duration</th>
                                        <th>View User Details</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>01</td>
                                        <td>A-141</td>
                                        <td>Marriage Counselling</td>
                                        <td>22-02-23</td>
                                        <td>09:00 AM</td>
                                        <td>Online (Video Call 30 min)</td>
                                        <td>
                                            <div className="Actions justify-content-start">
                                                <a href="completed-details.html" className="Blue ml-0">
                                                    <i className="fa fa-eye" />
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="NewActions justify-content-center">
                                                <a
                                                    href="enter-report-details-generate.html"
                                                    className="Blue"
                                                >
                                                    Generate &amp; Upload Report
                                                </a>
                                                <a
                                                    href="javascript:void(0);"
                                                    className="Yellow"
                                                    data-toggle="modal"
                                                    data-target="#ValuableFeedback"
                                                >
                                                    Rate User
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>01</td>
                                        <td>A-141</td>
                                        <td>Marriage Counselling</td>
                                        <td>22-02-23</td>
                                        <td>09:00 AM</td>
                                        <td>Online (Video Call 30 min)</td>
                                        <td>
                                            <div className="Actions justify-content-start">
                                                <a href="completed-details.html" className="Blue ml-0">
                                                    <i className="fa fa-eye" />
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="NewActions justify-content-center">
                                                <a href="enter-report-details-edit.html" className="Blue">
                                                    Edit &amp; Upload Report
                                                </a>
                                                <a
                                                    href="javascript:void(0);"
                                                    className="Yellow"
                                                    data-toggle="modal"
                                                    data-target="#ValuableFeedback"
                                                >
                                                    Rate User
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>01</td>
                                        <td>A-141</td>
                                        <td>Marriage Counselling</td>
                                        <td>22-02-23</td>
                                        <td>09:00 AM</td>
                                        <td>Online (Video Call 30 min)</td>
                                        <td>
                                            <div className="Actions justify-content-start">
                                                <a href="completed-details.html" className="Blue ml-0">
                                                    <i className="fa fa-eye" />
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="NewActions justify-content-center">
                                                <span className="Blue Opacity">
                                                    Generate &amp; Upload Report
                                                </span>
                                                <a
                                                    href="javascript:void(0);"
                                                    className="Yellow"
                                                    data-toggle="modal"
                                                    data-target="#ValuableFeedback"
                                                >
                                                    Rate User
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="Cancelled">
                        <div className="TitleBox justify-content-end m-0">
                            <a
                                href="javascript:void(0);"
                                className="TitleLink"
                                style={{ marginTop: "-85px" }}
                            >
                                Download CSV
                            </a>
                        </div>
                        <div className="Small-Wrapper">
                            <div className="FilterArea">
                                <div className="FilterLeft">
                                    <div className="form-group">
                                        <label>Search</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here.."
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <button className="Button">Apply</button>
                                        <button className="Button Cancel">
                                            <i className="fa fa-refresh" />
                                        </button>
                                    </div>
                                </div>
                                <div className="FilterRight">
                                    <div className="form-group">
                                        <label>Timeframe</label>
                                        <select className="form-control">
                                            <option>Select </option>
                                            <option value="Today">Today</option>
                                            <option value="Week">This Week</option>
                                            <option value="Month">This Month</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="TableList">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Appointment ID</th>
                                        <th>Service Name</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Call Type &amp; Duration</th>
                                        <th>Cancellation Reason</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>01</td>
                                        <td>A-141</td>
                                        <td>Marriage Counselling</td>
                                        <td>22-02-23</td>
                                        <td>09:00 AM</td>
                                        <td>Online (Video Call 30 min)</td>
                                        <td>Don't have a time</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="NoShow">
                        <div className="TitleBox justify-content-end m-0">
                            <a
                                href="javascript:void(0);"
                                className="TitleLink"
                                style={{ marginTop: "-85px" }}
                            >
                                Download CSV
                            </a>
                        </div>
                        <div className="Small-Wrapper">
                            <div className="FilterArea">
                                <div className="FilterLeft">
                                    <div className="form-group">
                                        <label>Search</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here.."
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <button className="Button">Apply</button>
                                        <button className="Button Cancel">
                                            <i className="fa fa-refresh" />
                                        </button>
                                    </div>
                                </div>
                                <div className="FilterRight">
                                    <div className="form-group">
                                        <label>Timeframe</label>
                                        <select className="form-control">
                                            <option>Select </option>
                                            <option value="Today">Today</option>
                                            <option value="Week">This Week</option>
                                            <option value="Month">This Month</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="TableList">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Appointment ID</th>
                                        <th>Service Name</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Call Type &amp; Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>01</td>
                                        <td>A-141</td>
                                        <td>Marriage Counselling</td>
                                        <td>22-02-23</td>
                                        <td>09:00 AM</td>
                                        <td>Online (Video Call 30 min)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Appointment;