import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../../../Redux/Slices/Profile/profile";
import { useDispatch } from "react-redux";
const initialState = {
    showLogoutModal: false,
    userImage: "",
    userName: ""
}
const Header = () => {
    const [iState, updateState] = useState(initialState)
    const { showLogoutModal, userImage, userName } = iState
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleLogoutModal = () => {
        updateState({ ...iState, showLogoutModal: true })
    }
    const handleLogout = () => {
        window.localStorage.removeItem('astrologerToken');
        navigate('/')
    }


    useEffect(() => {
        dispatch(getProfile()).then((res) => {
            console.log('res', res)
            updateState({
                ...iState,
                userName: res?.payload?.data?.fullName,
                userImage: res?.payload?.data?.image
            })
        })
    }, [])
    return (
        <div className="Header">
            <div className="HeaderLeft">
                {/* <div class="Toggle">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-left header-icon mt-1">
              <line x1="17" y1="10" x2="3" y2="10"></line>
              <line x1="21" y1="6" x2="3" y2="6"></line>
              <line x1="21" y1="14" x2="3" y2="14"></line>
              <line x1="17" y1="18" x2="3" y2="18"></line>
          </svg>
      </div> */}
                {/* <div class="SearchBox">
          <input type="search" class="form-control" placeholder="Search…">
          <span><i class="fa fa-search"></i></span>
      </div> */}
            </div>
            <div className="Navigation">
                {/* <div class="Bell">
          <a href="notification.html">
              <span class="Count">2</span>
              <i class="fa fa-bell"></i>
          </a>
      </div> */}
                <div className="Avater">
                    <a>
                        <figure>
                            <img src={userImage} />
                        </figure>
                        {userName}
                    </a>
                    <ul>
                        <li>
                            <figure>
                                <img src={userImage} />
                            </figure>
                            <h4>
                                {userName} <span>Administrator</span>
                            </h4>
                        </li>
                        <li>
                            <a
                                onClick={handleLogoutModal}
                                data-toggle="modal"
                                data-target="#LogOutModal"
                            >
                                <span>
                                    <i className="fa fa-sign-out" />
                                </span>
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="clear" />
            </div>
            <Modal
                show={showLogoutModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="modal-body">
                        <a className="CloseModal" onClick={() => updateState({ ...iState, showLogoutModal: false })}>
                            ×
                        </a>
                        <div className="LogOutModalArea">
                            <span>
                                <img src={require('../../../Assets/images/logout-icon.png')} />
                            </span>
                            <h5>Log Out</h5>
                            <p>Are you sure you want to log out?</p>
                            <div className="Buttons TwoButtons">
                                <button className="Button" onClick={() => updateState({ ...iState, showLogoutModal: false })}>
                                    No
                                </button>
                                <a
                                    type="button"
                                    onClick={handleLogout}
                                    className="Button Red"
                                >
                                    Yes
                                </a>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>

    )
}

export default Header;