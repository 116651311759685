import React from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";

const SetAvailability = () => {
    return (
        <>
            <SideNav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="Small-Wrapper">
                        <div className="SetAvailabilityArea">
                            <div className="SlotBoxMain">
                                <div className="SlotBox">
                                    <span>Monday</span>
                                    <p>
                                        <strong>Slot-0</strong>
                                        <strong>From: </strong>12:00 PM - <strong>To: </strong>1:00 AM
                                        <button data-toggle="modal" data-target="#EditSlot">
                                            <i className="fa-solid fa-pen-to-square" /> Edit
                                        </button>
                                        <button data-toggle="modal" data-target="#SlotDelete">
                                            {" "}
                                            <i className="fa-solid fa-trash" /> Delete
                                        </button>
                                    </p>
                                    <p>
                                        <strong>Slot-0</strong>
                                        <strong>From: </strong>12:00 PM - <strong>To: </strong>1:00 AM
                                        <button>
                                            <i className="fa-solid fa-pen-to-square" /> Edit
                                        </button>{" "}
                                        <button>
                                            {" "}
                                            <i className="fa-solid fa-trash" />
                                            Delete
                                        </button>
                                    </p>
                                </div>
                                <button data-toggle="modal" data-target="#AddSlot">
                                    <i className="fa-solid fa-plus" />
                                </button>
                            </div>
                            <div className="SlotBoxMain">
                                <div className="SlotBox">
                                    <span>Tuesday</span>
                                    <p>
                                        <strong>Slot-0</strong>
                                        <strong>From: </strong>12:00 PM - <strong>To: </strong>1:00 AM
                                        <button data-toggle="modal" data-target="#EditSlot">
                                            <i className="fa-solid fa-pen-to-square" /> Edit
                                        </button>
                                        <button data-toggle="modal" data-target="#SlotDelete">
                                            {" "}
                                            <i className="fa-solid fa-trash" /> Delete
                                        </button>
                                    </p>
                                    <p>
                                        <strong>Slot-0</strong>
                                        <strong>From: </strong>12:00 PM - <strong>To: </strong>1:00 AM
                                        <button>
                                            <i className="fa-solid fa-pen-to-square" /> Edit
                                        </button>{" "}
                                        <button>
                                            {" "}
                                            <i className="fa-solid fa-trash" />
                                            Delete
                                        </button>
                                    </p>
                                </div>
                                <button data-toggle="modal" data-target="#AddSlot">
                                    <i className="fa-solid fa-plus" />
                                </button>
                            </div>
                            <div className="SlotBoxMain">
                                <div className="SlotBox">
                                    <span>Wednesday</span>
                                    <p>No Availability</p>
                                </div>
                                <button data-toggle="modal" data-target="#AddSlot">
                                    <i className="fa-solid fa-plus" />
                                </button>
                            </div>
                            <div className="SlotBoxMain">
                                <div className="SlotBox">
                                    <span>Thursday</span>
                                    <p>No Availability</p>
                                </div>
                                <button data-toggle="modal" data-target="#AddSlot">
                                    <i className="fa-solid fa-plus" />
                                </button>
                            </div>
                            <div className="SlotBoxMain">
                                <div className="SlotBox">
                                    <span>Friday</span>
                                    <p>No Availability</p>
                                </div>
                                <button data-toggle="modal" data-target="#AddSlot">
                                    <i className="fa-solid fa-plus" />
                                </button>
                            </div>
                            <div className="SlotBoxMain">
                                <div className="SlotBox">
                                    <span>Saturday</span>
                                    <p>No Availability</p>
                                </div>
                                <button data-toggle="modal" data-target="#AddSlot">
                                    <i className="fa-solid fa-plus" />
                                </button>
                            </div>
                            <div className="SlotBoxMain">
                                <div className="SlotBox">
                                    <span>Sunday</span>
                                    <p>No Availability</p>
                                </div>
                                <button data-toggle="modal" data-target="#AddSlot">
                                    <i className="fa-solid fa-plus" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default SetAvailability