import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import OTPInput from "react-otp-input";
import { loginApiSlice, verifyOtp } from "../../Redux/Slices/Login/Login";
import { useLocation, useNavigate } from "react-router-dom";

const intialState = {
    localOtp: "",
    errors: {}
}

const Verification = () => {
    const [iState, updateState] = useState(intialState);
    const [otp, setOtp] = useState();
    const { localOtp, errors } = iState
    const { state } = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    console.log('state', state)

    const handleValidation = () => {
        let error = {}; // Use an object to store potential errors
        let isValid = true;

        // Check if OTP is empty
        if (!otp) {
            error.otp = "OTP is required";
            isValid = false;
        }
        // Check if OTP length is not 4 digits
        else if (otp.length !== 4) {
            error.otp = "OTP must be 4 digits";
            isValid = false;
        }
        // Check if OTP is not equal to the localOtp
        else if (otp != localOtp) {
            error.otp = "Invalid OTP";
            isValid = false;
        }

        // Update the component's state with any errors
        updateState({
            ...iState, // Spread existing state to avoid overwriting
            errors: error,
        });

        // Return true if valid, otherwise false
        return isValid;
    };



    const handleSubmit = (e) => {
        e.preventDefault()
        const data = {
            otp: Number(otp),
            contact: state?.contact
        }
        console.log('is valid', handleValidation(), 'otp', otp, 'local otp', localOtp)
        if (handleValidation()) {
            dispatch(verifyOtp(data)).then((res) => {
                if (res?.payload?.status === 200) {
                    if (res?.payload?.data?.astroExist) {
                        window.localStorage.setItem('astrologerToken', res?.payload?.data.astrologer.jwtToken)
                        navigate('/step-details')// check for approval
                    } else {
                        window.localStorage.setItem('astrologerToken', res?.payload?.data?.astrologer?.jwtToken)
                        navigate('/createAccount', { state: { contact: state?.contact } })
                    }
                }
            })
        }

    }

    const handleResend = () => {
        const data = {
            contact: Number(state?.contact)
        }
        dispatch(loginApiSlice(data)).then((res) => {
            console.log('before', res)
            if (res?.payload?.status == 200) {
                console.log('center', res)
                updateState({
                    ...iState,
                    localOtp: res?.payload?.data?.otp
                })
                setOtp("")
            }
        })
    }

    useEffect(() => {
        updateState({
            ...iState,
            localOtp: state?.otp
        })
    }, [])
    return (
        <div className="LoginArea">
            <div className="LoginBox">
                <figure>
                    <img src={require('../../Assets/images/Logo.png')} />
                </figure>
                <form
                    onSubmit={handleSubmit}>
                    <h3>OTP Verification</h3>
                    <p>
                        Please enter 4 digit OTP Verification code received on your email
                        address &amp; mobile no.
                    </p>

                    <div className="form-group">

                        <div className="OTPBox">
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={4}
                                renderSeparator={<span> </span>}
                                renderInput={(props) => <input {...props} />}
                            />

                        </div>
                        <span style={{ color: 'red' }}>{errors.otp}</span>

                        <p className="text-right">
                            <a onClick={handleResend}>Resend</a>
                            <span style={{ color: "brown" }}>{localOtp}</span>
                        </p>
                    </div>
                    <button className="Button" type="submit">
                        Verify OTP
                    </button>
                </form>
            </div>
        </div>

    )
}

export default Verification;