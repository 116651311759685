import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../header/Header'
import SideNav from '../sidenav/Sidenav'
import { useDispatch, useSelector } from 'react-redux'
import { supportResolutionApi } from '../../../Redux/Slices/Support/Support'
import moment from 'moment'

const SupportNotes = () => {
    const { state } = useLocation()
    const dispatch = useDispatch()
    const supportListData = useSelector((state) => state?.support?.resolutionData)
    const navigate = useNavigate()
    console.log("supportListData", supportListData)
    console.log('state', state)

    useEffect(() => {
        const data = {
            id: state
        }
        dispatch(supportResolutionApi(data))
    }, [])
    return (
        <div>
            <Header />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title">Resolution Notes</h4>
                        <div className="BackArrow mb-0">
                            <a onClick={() => navigate(-1)}>
                                <i className="fa fa-long-arrow-left" /> Back
                            </a>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="NotificationsBox">
                            <ul>
                                {
                                    supportListData?.data?.[0]?.notes?.map((item) => {
                                        return (<li>
                                            <aside>
                                                <div className="d-flex justify-content-between">
                                                    <h5>Notes :</h5>
                                                    <h6>
                                                        Status Updated By : <span>{item?.updatedBy}</span>
                                                    </h6>
                                                </div>
                                                <p>
                                                    {item?.message}
                                                </p>
                                                <div className="d-flex justify-content-between">
                                                    <h6>
                                                        Status : <span>{item?.updatedStatus}</span>
                                                    </h6>
                                                    <h6>{moment(item?.updatedAt).format('DD/MM/YYYY hh:mm a')}</h6>
                                                </div>
                                            </aside>
                                        </li>)
                                    })
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SupportNotes