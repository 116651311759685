import { combineReducers } from '@reduxjs/toolkit';
import supportReducer from "./Slices/Support/Support"
import GuideLinesReducer from './Slices/GuideLines/GuideLines'
import locationReducer from './Slices/Location/location';
import FaqReducer from './Slices/Faq/Faq';

const rootReducer = combineReducers({
    support: supportReducer,
    guideLines: GuideLinesReducer,
    locationData: locationReducer,
    faqData: FaqReducer
});

export default rootReducer