import React, { useEffect, useState } from "react";
import StepBox from "./StepBox";
import { ProfileUpdate } from "../../Redux/Slices/ProfileUpdate/ProfileUpdate";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userIsRegistered } from "../../Redux/Slices/Login/Login";

const initialState = {
    mainSourceOfIncome: "",
    otherSourceOfIncome: "",
    currentlyFullTimeWorking: "",
    highestQualification: "",
    otherHighestQualification: "",
    fromWhereDoYouLearnAstrology: "",
    instagramLink: "",
    facebookLink: "",
    minExpection: "",
    errors: {}
}
const FillOthers = () => {
    const [iState, updateState] = useState(initialState)
    const { mainSourceOfIncome,
        otherSourceOfIncome,
        currentlyFullTimeWorking,
        highestQualification,
        otherHighestQualification,
        fromWhereDoYouLearnAstrology,
        instagramLink,
        facebookLink,
        minExpection, errors } = iState

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({ ...iState, [name]: value })
    }

    const handleValidation = () => {
        let error = {};
        let isValid = true;

        if (!mainSourceOfIncome) {
            error.mainSourceOfIncome = "Main source of income is required";
            isValid = false;
        }

        // Only validate otherSourceOfIncome if mainSourceOfIncome is "Others"
        if (mainSourceOfIncome === "Others" && !otherSourceOfIncome) {
            error.otherSourceOfIncome = "Please specify other source of income";
            isValid = false;
        }

        if (!currentlyFullTimeWorking) {
            error.currentlyFullTimeWorking =
                "Please select if you are currently working full time";
            isValid = false;
        }

        if (!highestQualification) {
            error.highestQualification = "Please select your highest qualification";
            isValid = false;
        }

        // Only validate otherHighestQualification if highestQualification is "Others"
        if (highestQualification === "Others" && !otherHighestQualification) {
            error.otherHighestQualification =
                "Please specify your other highest qualification";
            isValid = false;
        }

        if (!fromWhereDoYouLearnAstrology) {
            error.fromWhereDoYouLearnAstrology =
                "Please provide details about where you learned astrology";
            isValid = false;
        }

        // Optional fields, but validate format if provided
        if (
            instagramLink &&
            !/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w\.-]*)*\/?$/i.test(
                instagramLink
            )
        ) {
            error.instagramLink = "Please enter a valid Instagram link";
            isValid = false;
        }

        if (
            facebookLink &&
            !/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w\.-]*)*\/?$/i.test(
                facebookLink
            )
        ) {
            error.facebookLink = "Please enter a valid Facebook link";
            isValid = false;
        }

        if (!minExpection) {
            error.minExpection = "Minimum earning expectation is required";
            isValid = false;
        } else if (isNaN(Number(minExpection)) || Number(minExpection) <= 0) {
            error.minExpection = "Please enter a valid positive number";
            isValid = false;
        }

        updateState({
            ...iState,
            errors: error,
        });

        return isValid;
    };

    const handleSubmitOtherDetails = () => {
        const data = {
            sourceOfIncome: mainSourceOfIncome,
            ifSourceOfIncomeIsOthers: otherSourceOfIncome,
            fullTimeJob: currentlyFullTimeWorking,
            highestQualification: highestQualification,
            isHighestQualificationIsOthers: otherHighestQualification,
            learnAstrology: fromWhereDoYouLearnAstrology,
            instaLink: instagramLink,
            facebookLink: facebookLink,
            minEarnExpected: Number(minExpection),
        }
        if (handleValidation()) {
            dispatch(ProfileUpdate(data)).then((res) => {
                console.log('before', res);
                if (res?.payload?.status === 200) {
                    navigate('/document')
                }
            })
        }

    }

    const handleLogout = () => {
        console.log("handleLogout")
        window.localStorage.removeItem('astrologerToken');
        navigate('/')
    }

    useEffect(() => {
        dispatch(userIsRegistered()).then((res) => {
            if (res?.payload?.status === 200) {
                console.log('res', res?.payload?.data?.astrologer)
                updateState({
                    ...iState,
                    mainSourceOfIncome: res?.payload?.data?.astrologer?.sourceOfIncome ?? "",
                    otherSourceOfIncome: res?.payload?.data?.astrologer?.ifSourceOfIncomeIsOthers ?? "",
                    currentlyFullTimeWorking: res?.payload?.data?.astrologer?.fullTimeJob ?? "",
                    highestQualification: res?.payload?.data?.astrologer?.highestQualification ?? "",
                    otherHighestQualification: res?.payload?.data?.astrologer?.isHighestQualificationIsOthers ?? "",
                    fromWhereDoYouLearnAstrology: res?.payload?.data?.astrologer?.learnAstrology ?? "",
                    instagramLink: res?.payload?.data?.astrologer?.instaLink ?? "",
                    facebookLink: res?.payload?.data?.astrologer?.facebookLink ?? "",
                    minExpection: Number(res?.payload?.data?.astrologer?.minEarnExpected) ?? Number("0"),
                })
            }
        })
    }, [])
    return (
        <>
            <div style={{ backgroundColor: "#f6f7ff" }}>
                <div className="Header Step">
                    <div className="HeaderLeft">
                        <div className="Logo">
                            <img src={require('../../Assets/images/Logo.png')} />
                        </div>
                    </div>
                    <div className="Navigation">
                        <div className="Avater">
                            <a >
                                <figure>
                                    <img src={require('../../Assets/images/profile.jpg')} />
                                </figure>
                                {/* Bob Hyden */}
                            </a>
                            <ul>
                                <li>
                                    <figure>
                                        <img src={require('../../Assets/images/profile.jpg')} />
                                    </figure>
                                    <h4>
                                        <span>Astrologer</span>
                                    </h4>
                                </li>
                                <li>
                                    <a
                                        data-toggle="modal"
                                        data-target="#LogOutModal"
                                        onClick={handleLogout}
                                    >
                                        <span >
                                            <i className="fa fa-sign-out" />
                                        </span>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="clear" />
                    </div>
                </div>
                <div>
                    <StepBox />
                    <div className="SmallWrapperBox" style={{ width: "40%" }}>
                        <div className="ConsultantService CommonForm">
                            <form>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Main Source of Income</label>
                                            {/* <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Main Source of Income"
                                                name="mainSourceOfIncome"
                                                value={mainSourceOfIncome}
                                                onChange={handleChange}
                                            /> */}
                                            <select
                                                className="form-control"
                                                name="mainSourceOfIncome"
                                                value={mainSourceOfIncome}
                                                onChange={handleChange}
                                            >
                                                <option value="">--select--</option>
                                                <option value="ownBussiness">Own Bussiness</option>
                                                <option value="selfEmployed">Self Employed</option>
                                                <option value="Others">Others</option> {/* Add an "Others" option */}
                                            </select>
                                            {errors.mainSourceOfIncome && (
                                                <span className="text-danger">{errors.mainSourceOfIncome}</span>
                                            )}
                                        </div>
                                        {
                                            mainSourceOfIncome === "Others" && <div className="form-group">
                                                <label>If main source of income is others</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Specify other source of income"
                                                    name="otherSourceOfIncome"
                                                    value={otherSourceOfIncome}
                                                    onChange={handleChange}
                                                />
                                                {errors.otherSourceOfIncome && (
                                                    <span className="text-danger">{errors.otherSourceOfIncome}</span>
                                                )}
                                            </div>
                                        }

                                        <div className="form-group">
                                            <label>Are you currently working a full time job?</label>
                                            <ul className="GenderUl">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Yes
                                                        <input
                                                            type="radio"
                                                            name="currentlyFullTimeWorking"
                                                            value="yes"
                                                            checked={currentlyFullTimeWorking === "yes"}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        No
                                                        <input
                                                            type="radio"
                                                            name="currentlyFullTimeWorking"
                                                            value="no"
                                                            checked={currentlyFullTimeWorking === "no"}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                            {errors.currentlyFullTimeWorking && (
                                                <span className="text-danger">{errors.currentlyFullTimeWorking}</span>
                                            )}
                                        </div>

                                        <div className="form-group">
                                            <label>Highest Qualification</label>
                                            <select
                                                className="form-control"
                                                name="highestQualification"
                                                value={highestQualification}
                                                onChange={handleChange}
                                            >
                                                <option value="">--select--</option>
                                                <option value="10">10th</option>
                                                <option value="12">12th</option>
                                                <option value="graduate">Graduate</option>
                                                <option value="Others">Others</option> {/* Add an "Others" option */}
                                            </select>
                                            {errors.highestQualification && (
                                                <span className="text-danger">{errors.highestQualification}</span>
                                            )}
                                        </div>
                                        {/* Conditionally render the "otherHighestQualification" field */}
                                        {highestQualification === "Others" && (
                                            <div className="form-group">
                                                <label>If highest qualification is others</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Others"
                                                    name="otherHighestQualification"
                                                    value={otherHighestQualification}
                                                    onChange={handleChange}
                                                />
                                                {errors.otherHighestQualification && (
                                                    <span className="text-danger">{errors.otherHighestQualification}</span>
                                                )}
                                            </div>
                                        )}
                                        <div className="form-group">
                                            <label>From where did you learn Astrology ?</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter details about where you learned astrology"
                                                name="fromWhereDoYouLearnAstrology"
                                                value={fromWhereDoYouLearnAstrology}
                                                onChange={handleChange}
                                            />
                                            {errors.fromWhereDoYouLearnAstrology && (
                                                <span className="text-danger">{errors.fromWhereDoYouLearnAstrology}</span>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label>Instagram Profile Link (Optional)</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter your Instagram profile link"
                                                name="instagramLink"
                                                value={instagramLink}
                                                onChange={handleChange}
                                            />
                                            {errors.instagramLink && (
                                                <span className="text-danger">{errors.instagramLink}</span>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label>Facebook Profile Link (Optional)</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter your Facebook profile link"
                                                name="facebookLink"
                                                value={facebookLink}
                                                onChange={handleChange}
                                            />
                                            {errors.facebookLink && (
                                                <span className="text-danger">{errors.facebookLink}</span>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label>Min. Earning Expectations from AstroTech</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter your minimum earning expectation"
                                                name="minExpection"
                                                value={minExpection}
                                                onChange={handleChange}
                                            />
                                            {errors.minExpection && (
                                                <span className="text-danger">{errors.minExpection}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="Buttons TwoColorButtons">
                                    <Link className="ContinueBtn Red" to="/skills">
                                        Back
                                    </Link>
                                    <a className="ContinueBtn" onClick={handleSubmitOtherDetails}>
                                        Save &amp; Continue
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FillOthers;