import React from "react";
import SideNav from "../sidenav/Sidenav";
import Header from "../header/Header";

const Profile = () => {
    return (
        <>
            <SideNav />
            <Header />
            <div className="WrapperArea">
                <div className="WrapperBox">
                <div class="TitleBox"><h4 class="Title">Profile</h4></div>
                    <div className="CommonTabs">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    data-toggle="tab"
                                    href="#PersonalDetails"
                                >
                                    Personal Details
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#SkillsExperienceDetails"
                                >
                                    Skills or Experience Details
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#AdditionalInformation"
                                >
                                    Additional Information
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#Services">
                                    Services
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="PersonalDetails">
                            <div className="Small-Wrapper">
                                <fieldset disabled="">
                                    <div className="CommonForm">
                                        <form>
                                            <div className="UserPlanBox">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Full Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Full Name"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Email ID</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Your Email ID"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Contact Number</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Contact Number"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>DOB</label>
                                                            <input type="date" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Marital Status</label>
                                                            <select className="form-control">
                                                                <option>--select--</option>
                                                                <option>married</option>
                                                                <option>Unmarried</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Gender</label>
                                                            <ul className="UserPlan text-left">
                                                                <li>
                                                                    <label className="Radio">
                                                                        {" "}
                                                                        Male
                                                                        <input type="radio" name="plan" />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    <label className="Radio">
                                                                        {" "}
                                                                        Female
                                                                        <input type="radio" name="plan" />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    <label className="Radio">
                                                                        {" "}
                                                                        Others
                                                                        <input type="radio" name="plan" />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Address Line 1</label>
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Address Line 2</label>
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group">
                                                            <label>City</label>
                                                            <select className="form-control">
                                                                <option>--select--</option>
                                                                <option>Haryana</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group">
                                                            <label>State</label>
                                                            <select className="form-control">
                                                                <option>--select--</option>
                                                                <option>Haryana</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group">
                                                            <label>Country</label>
                                                            <select className="form-control">
                                                                <option>--select--</option>
                                                                <option>india</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group">
                                                            <label>Pincode</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Pincode"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="SkillsExperienceDetails">
                            <div className="Small-Wrapper">
                                <fieldset disabled="">
                                    <div className="CommonForm">
                                        <form>
                                            <div className="UserPlanBox">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Display Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Display Name"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Language</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="English, Hindi"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Experience (in years)</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="7 years"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Skills</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Kundli Expert, Panchang, Baby Nameing"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>How many hours you can contribute daily?</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Graduate"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>About Yourself </label>
                                                            <textarea
                                                                rows={5}
                                                                className="form-control"
                                                                placeholder="I am very hard working"
                                                                defaultValue={""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="AdditionalInformation">
                            <div className="Small-Wrapper">
                                <fieldset disabled="">
                                    <div className="CommonForm">
                                        <form>
                                            <div className="UserPlanBox">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Main Source of Income</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Own Business"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>If main source of income is others</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Freelancer"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Are you currently working a full time job?
                                                            </label>
                                                            <ul className="UserPlan text-left">
                                                                <li>
                                                                    <label className="Radio">
                                                                        {" "}
                                                                        Yes
                                                                        <input type="radio" name="plan" />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    <label className="Radio">
                                                                        {" "}
                                                                        No
                                                                        <input
                                                                            type="radio"
                                                                            name="plan"
                                                                            defaultChecked=""
                                                                        />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Select your highest qualification</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Graduate"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>If highest qualification is others</label>
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>From where did you learn Astrology?</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Facebook"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Instagram profile link (optional)</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="avinash.instagram.com"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Facebook profile link (optional)</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="avinash.facebook.com"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Min. Earning Expectations from AstroTech (in month)
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="1 lakh"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Where did you hear Astrotech?</label>
                                                            <select className="form-control">
                                                                <option>--Select--</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>If Others</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Other"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Did anybody refer you to Astrotech?</label>
                                                            <ul className="UserPlan text-left">
                                                                <li>
                                                                    <label className="Radio">
                                                                        {" "}
                                                                        Yes
                                                                        <input type="radio" name="plan1" />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    <label className="Radio">
                                                                        {" "}
                                                                        No
                                                                        <input
                                                                            type="radio"
                                                                            name="plan1"
                                                                            defaultChecked=""
                                                                        />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Are you working on any other online platform?
                                                            </label>
                                                            <ul className="UserPlan text-left">
                                                                <li>
                                                                    <label className="Radio">
                                                                        {" "}
                                                                        Yes
                                                                        <input type="radio" name="plan" />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    <label className="Radio">
                                                                        {" "}
                                                                        No
                                                                        <input
                                                                            type="radio"
                                                                            name="plan"
                                                                            defaultChecked=""
                                                                        />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <div className="form-group">
                                                            <label>Aadhar Card</label>
                                                            <span>
                                                                <i className="fa-solid fa-file-pdf" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <div className="form-group">
                                                            <label>PAN Card</label>
                                                            <span>
                                                                <i className="fa-solid fa-file-pdf" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <div className="form-group">
                                                            <label>Password</label>
                                                            <span>
                                                                <i className="fa-solid fa-file-pdf" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* <div class="col-lg-6">
                                          <div class="form-group">
                                              <label>Marital Status</label>
                                              <select class="form-control">
                                                  <option>--select--</option>
                                                  <option>married</option>
                                                  <option>Unmarried</option>
                                              </select>
                                          </div>
                                      </div> */}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="Services">
                            <h2
                                style={{
                                    color: "#000",
                                    fontWeight: 600,
                                    fontSize: 18,
                                    lineHeight: 28,
                                    margin: "0 0 25px 0"
                                }}
                            >
                                Please choose from the following services you will provide:
                            </h2>
                            <div className="StaticArea">
                                <div id="accordion">
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse0"
                                            aria-expanded="true"
                                        >
                                            <h4>In-Depth kundli Analysis</h4>
                                            <a href="service-edit.html">
                                                <i className="fa fa-pencil" />
                                            </a>
                                        </div>
                                        <div id="collapse0" className="panel-collapse collapse in">
                                            <div className="card-body">
                                                <p>
                                                    As an astrologer on AstroTech, you will provide the
                                                    "In-Depth Kundli Analysis" service. This comprehensive
                                                    analysis offers personalized insights based on Vedic
                                                    astrology principles, helping clients understand their lives
                                                    and make informed decisions. Key topics to cover during the
                                                    consultation include:
                                                </p>
                                                <p>
                                                    {" "}
                                                    <span>Topics to Cover : </span> Personal Traits and
                                                    Characteristics , Relationships and Compatibility, Career
                                                    and Profession Financial Prospects, Health and Well-being
                                                    Education and Learning, Life Events and Predictions,
                                                    Remedies and Solutions
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse1"
                                            aria-expanded="true"
                                        >
                                            <h4>Marriage Counselling.</h4>
                                            <a href="service-edit.html">
                                                <i className="fa fa-pencil" />
                                            </a>
                                        </div>
                                        <div id="collapse1" className="panel-collapse collapse in">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse2"
                                        >
                                            <h4>Detailed Kundli Report.</h4>
                                            <a href="service-edit.html">
                                                <i className="fa fa-pencil" />
                                            </a>
                                        </div>
                                        <div id="collapse2" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse3"
                                        >
                                            <h4>Career Counselling.</h4>
                                            <a href="service-edit.html">
                                                <i className="fa fa-pencil" />
                                            </a>
                                        </div>
                                        <div id="collapse3" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse4"
                                        >
                                            <h4>Nameing Of New Bord.</h4>
                                            <a href="service-edit.html">
                                                <i className="fa fa-pencil" />
                                            </a>
                                        </div>
                                        <div id="collapse4" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse5"
                                        >
                                            <h4>Starting A New Business.</h4>
                                            <a href="service-edit.html">
                                                <i className="fa fa-pencil" />
                                            </a>
                                        </div>
                                        <div id="collapse5" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse6"
                                        >
                                            <h4>Vastu.</h4>
                                            <a href="service-edit.html">
                                                <i className="fa fa-pencil" />
                                            </a>
                                        </div>
                                        <div id="collapse6" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Profile;