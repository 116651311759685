import React from "react";
import { Link, useLocation } from "react-router-dom";

const StepBox = (props) => {
    const { pathname } = useLocation()
    let page = props.page ? '1' : props.page
    console.log('props', props)
    console.log('location', pathname)
    return (
        <div className="StepBox">
            <ul>
                <li className="Current">
                    <span className={pathname === '/personal' ? "Circle" : "CircleImg"}>
                        <img src={require('../../Assets/images/first-img.png')} />
                    </span>
                    <Link className="Text"
                        to='/personal'
                    // to={page < '1' ? '' : "/personal"}
                    >Personal Details</Link>
                </li>
                <li>
                    <span className={pathname === '/skills' ? "Circle" : "CircleImg"}>
                        <img src={require('../../Assets/images/first-img.png')} />
                    </span>
                    <Link className="Text"
                        to='/skills'
                    // to={page <= '2' ? '' : "/skills"}
                    >Skills or Experience Details</Link>
                </li>
                <li>
                    <span className={pathname === '/fill-others' ? "Circle" : "CircleImg"}>
                        <img src={require('../../Assets/images/first-img.png')} />
                    </span>
                    <Link
                        className="Text"
                        to='/fill-others'>Fill Other Details</Link>
                </li>
                <li>
                    <span className={pathname === '/document' ? "Circle" : "CircleImg"}>
                        <img src={require('../../Assets/images/first-img.png')} />
                    </span>
                    <Link className="Text" to='/document'>Document uplaod</Link>
                </li>
            </ul>
        </div>

    )
}
export default StepBox;